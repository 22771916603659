import bimg26 from "../subassets/bagris images/New folder/activity 3.jpg";
import bimg27 from "../subassets/bagris images/New folder/activity 4.jpg";
import bimg28 from "../subassets/bagris images/New folder/activity 5.jpg";
import bimg29 from "../subassets/bagris images/New folder/activity 6.jpg";
import bimg30 from "../subassets/bagris images/New folder/wallpaper4.jpg";
import bimg31 from "../subassets/bagris images/New folder/wallpaper2.jpg";
import bimg32 from "../subassets/bagris images/New folder/wallpaper3.jpg";
import bimg33 from "../subassets/bagris images/New folder/wallpaper4.jpg";
import bimg34 from "../subassets/bagris images/New folder/wallpaper5.jpg";
import bimg35 from "../subassets/bagris images/New folder/wallpaper6.jpg";
import bimg36 from "../subassets/bagris images/New folder/wallpaper7.jpg";
import bimg37 from "../subassets/bagris images/New folder/wallpaper8.jpg";

export const activityImages = [+
  { name: "bimg26", src: bimg26 },
  { name: "bimg27", src: bimg27 },
  { name: "bimg28", src: bimg28 },
  { name: "bimg29", src: bimg29 },
  { name: "bimg30", src: bimg30 },
  { name: "bimg31", src: bimg31 },
  { name: "bimg32", src: bimg32 },
  { name: "bimg33", src: bimg33 },
  { name: "bimg34", src: bimg34 },
  { name: "bimg35", src: bimg35 },
  { name: "bimg36", src: bimg36 },
  { name: "bimg37", src: bimg37 },
];
