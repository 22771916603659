import React from "react";
import { FaCameraRetro } from "react-icons/fa";
import Bacakgr from "../subassets/bagris images/amit manral/compressing click img/backgroundofimg.jpg";
import assetimg1 from "../subassets/bagris images/amit manral/compressing click img/pic 1.jpg";
import assetimg2 from "../subassets/bagris images/amit manral/compressing click img/pic 2.jpg";
import assetimg3 from "../subassets/bagris images/amit manral/compressing click img/pic 3.jpg"
import assetimg4 from "../subassets/bagris images/amit manral/compressing click img/pic 4.jpg"
import assetimg5 from "../subassets/bagris images/amit manral/compressing click img/pic 5.jpg";
import assetimg6 from "../subassets/bagris images/amit manral/compressing click img/pic6.jpg";
import assetimg7 from "../subassets/bagris images/amit manral/compressing click img/pic7.jpg";
import assetimg8 from "../subassets/bagris images/amit manral/compressing click img/pic 8.jpg";
import assetimg9 from "../subassets/bagris images/amit manral/compressing click img/pic 9.jpg";
import assetimg10 from "../subassets/bagris images/amit manral/compressing click img/pic 10.jpg";
import assetimg11 from "../subassets/bagris images/amit manral/compressing click img/pic 11.jpg";
import assetimg12 from "../subassets/bagris images/amit manral/compressing click img/pic 12.jpg";
import assetimg13 from "../subassets/bagris images/amit manral/compressing click img/pic 13.jpg";
import assetimg14 from "../subassets/bagris images/amit manral/compressing click img/pic 14.jpg";
import assetimg15 from "../subassets/bagris images/amit manral/compressing click img/pic 15.jpg";
import assetimg16 from "../subassets/bagris images/amit manral/compressing click img/pic 16.jpg";
import assetimg17 from "../subassets/bagris images/amit manral/compressing click img/pic 17.jpg";
import assetimg18 from "../subassets/bagris images/amit manral/compressing click img/pic 18.jpg";
import assetimg19 from "../subassets/bagris images/amit manral/compressing click img/pic 19.jpg";
import assetimg20 from "../subassets/bagris images/amit manral/compressing click img/pic 20.jpg";
import assetimg21 from "../subassets/bagris images/amit manral/compressing click img/pic 21.jpg";
import assetimg22 from "../subassets/bagris images/amit manral/compressing click img/pic 22.jpg";
import assetimg23 from "../subassets/bagris images/amit manral/compressing click img/pic 23.jpg";
import assetimg24 from "../subassets/bagris images/amit manral/compressing click img/pic 24.jpg";
import assetimg25 from "../subassets/bagris images/amit manral/compressing click img/pic 25.jpg";
import assetimg26 from "../subassets/bagris images/amit manral/compressing click img/pic 26.jpg";
import assetimg27 from "../subassets/bagris images/amit manral/compressing click img/pic 27.jpg";
import assetimg28 from "../subassets/bagris images/amit manral/compressing click img/pic 28.jpg";
import assetimg29 from "../subassets/bagris images/amit manral/compressing click img/pic 29.jpg";
import assetimg30 from "../subassets/bagris images/amit manral/compressing click img/pic 30.jpg";
import assetimg31 from "../subassets/bagris images/amit manral/compressing click img/pic 31.jpg";
import assetimg32 from "../subassets/bagris images/amit manral/compressing click img/pic 32.jpg";
import assetimg33 from "../subassets/bagris images/amit manral/compressing click img/pic 33.jpg";
import assetimg34 from "../subassets/bagris images/amit manral/compressing click img/pic 34.jpg";
import assetimg35 from "../subassets/bagris images/amit manral/compressing click img/pic 35.jpg";
import assetimg36 from "../subassets/bagris images/amit manral/compressing click img/pic 36.jpg";
import assetimg37 from "../subassets/bagris images/amit manral/compressing click img/pic 37.jpg";
import assetimg38 from "../subassets/bagris images/amit manral/compressing click img/pic 38.jpg";
import assetimg39 from "../subassets/bagris images/amit manral/compressing click img/pic 39.jpg";
import assetimg40 from "../subassets/bagris images/amit manral/compressing click img/pic 40.jpg";
import assetimg41 from "../subassets/bagris images/amit manral/compressing click img/pic 41.jpg";
import assetimg42 from "../subassets/bagris images/amit manral/compressing click img/pic 42.jpg";
import assetimg43 from "../subassets/bagris images/amit manral/compressing click img/pic 43.jpg";
import assetimg44 from "../subassets/bagris images/amit manral/compressing click img/pic 44.jpg";
import assetimg45 from "../subassets/bagris images/amit manral/compressing click img/pic 45.jpg";
import assetimg455 from "../subassets/bagris images/amit manral/compressing click img/pic 46.jpg";
import assetimg47 from "../subassets/bagris images/amit manral/compressing click img/pic 47.jpg";
import assetimg48 from "../subassets/bagris images/amit manral/compressing click img/pic 48.jpg";
import assetimg49 from "../subassets/bagris images/amit manral/compressing click img/pic 49.jpg";
import assetimg50 from "../subassets/bagris images/amit manral/compressing click img/pic 50.jpg";
import assetimg51 from "../subassets/bagris images/amit manral/compressing click img/pic 51.jpg";
import assetimg52 from "../subassets/bagris images/amit manral/compressing click img/pic 52.jpg";
import assetimg53 from "../subassets/bagris images/amit manral/compressing click img/pic 53.jpg";
import assetimg54 from "../subassets/bagris images/amit manral/compressing click img/pic 54.jpg";
function Collage1() {
  const imagesmapp = [
    {
      colImages: assetimg1,
    },
    {
      colImages: assetimg2,
    },
    {
      colImages: assetimg3,
    },
    {
      colImages: assetimg4,
    },
    {
      colImages: assetimg5,
    },
    {
      colImages: assetimg6,
    },
    {
      colImages: assetimg7,
    },
    {
      colImages: assetimg8,
    },
    
    {
      colImages: assetimg9,
    },
    {
      colImages: assetimg10,
    },
    {
      colImages: assetimg11,
    },
    {
      colImages: assetimg12,
    },
    {
      colImages: assetimg13,
    },
    {
      colImages: assetimg14,
    },
    {
      colImages: assetimg15,
    },
    {
      colImages: assetimg16,
    },  {
      colImages: assetimg17,
    },
    {
      colImages: assetimg18,
    },
    {
      colImages: assetimg19,
    },
    {
      colImages: assetimg20,
    },
    {
      colImages: assetimg21,
    },
    {
      colImages: assetimg22,
    },
    {
      colImages: assetimg23,
    },
    {
      colImages: assetimg24,
    },  {
      colImages: assetimg25,
    },
    {
      colImages: assetimg26,
    },
    {
      colImages: assetimg27,
    },
    {
      colImages: assetimg28,
    },
    {
      colImages: assetimg29,
    },
    {
      colImages: assetimg30,
    },
    {
      colImages: assetimg31,
    },
    {
      colImages: assetimg32,
    },  {
      colImages: assetimg33,
    },
    {
      colImages: assetimg34,
    },
    {
      colImages: assetimg35,
    },
    {
      colImages: assetimg36,
    },
    {
      colImages: assetimg37,
    },
    {
      colImages: assetimg38,
    },
    {
      colImages: assetimg39,
    },
    {
      colImages: assetimg40,
    },  {
      colImages: assetimg41,
    },
    {
      colImages: assetimg42,
    },
    {
      colImages: assetimg43,
    },
    {
      colImages: assetimg44,
    },
    {
      colImages: assetimg45,
    },
    {
      colImages: assetimg455,
    },
    {
      colImages: assetimg47,
    },
    {
      colImages: assetimg48,
    },  {
      colImages: assetimg49,
    },
    {
      colImages: assetimg50,
    },
    {
      colImages: assetimg51,
    },
    {
      colImages: assetimg52,
    },
    {
      colImages: assetimg53,
    },
    {
      colImages: assetimg54,
    },

  ];
  return (
      <div className="m-auto">
        <div className="h-[20rem] w-450:h-[24rem] sm:h-[30rem] md:h-[40rem] m-auto ">
          <img src={Bacakgr} alt="" className="w-[100%] h-[100%] object-cover" />
        </div>
        <div className="w-full text-red-950 text-center mt-10">
          {" "}
          <p className="text-3xl sm:text-5xl  font-custom ">My Stories</p>
        </div>
        <div className="text-center m-10  text-xl sm:text-2xl font-custom">Karan & Ritu</div>
        <div className="m-auto flex flex-wrap justify-center gap-y-1 max-w-screen-lg">
          {imagesmapp.map((colimg, index) => {
            return (
              <div
                className="w-[20rem]  ml-1 hover:opacity-85"
                key={index}
              >
                <img
                  src={colimg.colImages}
                  alt={"img"}
                  className=" w-full h-full object-cover"
                style={{objectFit:"cover"}}
                />
              </div>
            );
          })}
        </div>

        <div className="flex justify-center items-center  px-5 rounded-md shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] mx-5 sm:mx-20 md:mx-40 mt-20 bg-slate-200 py-4 ">
        "As the bride takes her leave, she carries with her the love and memories of her past, stepping forward into a new chapter with courage and grace. Each step is a tribute to her journey so far, and a promise of the joy and adventure that lies ahead. With tears and smiles mingling, she embraces the future, guided by the love and blessings of those who hold her dear "
        </div>
          <div className="flex flex-col w-450:flex-row m-auto justify-center mt-7 items-center gap-2 font-custom"><FaCameraRetro /> Bajrang bagri photography</div>
      </div>
  );
}

export default Collage1;
